import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import '../assets/scss/components/_banner.scss';

export const Banner = () => {
  return (
    <a href="https://www.quranplan.com/" target="_blank" className="Banner-cont" rel="noopener noreferrer">
      <StaticImage
        src="../assets/images/quranplan-500x125.png" //top banner
        alt="banner"
        layout="constrained"
        width={500} // Image Size 500px x 125px
      />
    </a>
  );
};
export const AllRecitersBanner = () => {
  return (
    <a href="https://www.quranplan.com/" target="_blank" className="Banner-cont" rel="noopener noreferrer">
      <StaticImage
        src="../assets/images/quranplan-500x125.png" //top banner
        alt="banner"
        layout="constrained"
        width={500} // Image Size 500px x 125px
      />
    </a>
  );
};
export const AllRecitersBannerFirst = () => {
  return (
    <a href="https://www.quranplan.com/" target="_blank" className="Banner-cont" rel="noopener noreferrer">
      <StaticImage
        src="../assets/images/quranplan-500x125.png" //top banner
        alt="banner"
        layout="constrained"
        width={500} // Image Size 500px x 125px
      />
    </a>
  );
};
export const AllRecitersBannerSecond = () => {
  return (
    <a href="https://t.me/quranplayermp3" target="_blank" className="Banner-cont" rel="noopener noreferrer">
      <StaticImage
        src="../assets/images/telegram-500x125.png" //top banner
        alt="banner"
        layout="constrained"
        width={500} // Image Size 500px x 125px
      />
    </a>
  );
};
export const AlltranslationsBannerFirst= () => {
  return (
    <a href="https://www.quranplan.com/" target="_blank" className="Banner-cont" rel="noopener noreferrer">
      <StaticImage
        src="../assets/images/quranplan-500x125.png" //top banner
        alt="banner"
        layout="constrained"
        width={500} // Image Size 500px x 125px
      />
    </a>
  );
};
export const AlltranslationsBannerSecond = () => {
  return (
    <a href="https://t.me/quranplayermp3" target="_blank" className="Banner-cont" rel="noopener noreferrer">
      <StaticImage
        src="../assets/images/telegram-500x125.png" //top banner
        alt="banner"
        layout="constrained"
        width={500} // Image Size 500px x 125px
      />
    </a>
  );
};

export const ShareBanner = () => {
  return (
    <a href="https://t.me/quranplayermp3" target="_blank" className="Banner-cont" rel="noopener noreferrer">
      <StaticImage
        src="../assets/images/quranplan-500x125.png" //top banner
        alt="banner"
        layout="constrained"
        width={500} // Image Size 500px x 125px
      />
    </a>
  );
};

