import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import HomeHeroSubscription from "./HomeHeroSubscription";
import SocialLinks from "./SocialLinks";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { Banner }  from "../templates/banner";
const HomeHero = () => {
  const { site } = useStaticQuery(graphql`
    query coverImage {
      site {
        siteMetadata {
          title
          cover
        }
      }
    }
  `);
  useEffect(() => {
    if (window.location.pathname.includes("/ar")) {
      const dots = document.querySelectorAll('.dot');
      dots.forEach(dot => {
        dot.style.right = '47.5%';
        dot.style.setProperty('right', '47.5%', 'important'); 
      });
      const img = document.querySelector('.home-cover-wrap');
      img.style.setProperty('transform', 'translateY(30px)', 'important'); 
    }
  }, []);
  
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <section className="home-cover-area">
      <div className="container">
        <div
          className={`row home-cover-wrap${
            site.siteMetadata.cover && site.siteMetadata.cover !== ""
              ? " has-cover-image"
              : ""
          }`}
        >
          {site.siteMetadata.cover && site.siteMetadata.cover !== "" && (
            <div className="col-lg-5">
              <div className="cover-img-container">
                <div className="cover-img-wrap">
                  <a href="/all-reciters/">
                    <StaticImage
                      src="../assets/images/cover.jpg"
                      alt={`${site.siteMetadata.title} Cover`}
                      width={400}
                      height={400}
                      style={{ position: "absolute", borderRadius: "50%" }}
                      placeholder="blurred"
                    />
                  </a>
                  <div className="dot-parent dot-1">
                    <div className="dot"></div>
                  </div>
                  <div className="dot-parent dot-2">
                    <div className="dot"></div>
                  </div>
                  <div className="dot-parent dot-3">
                    <div className="dot"></div>
                  </div>
                  <div className="dot-parent dot-4">
                    <div className="dot"></div>
                  </div>
                  <div className="dot-parent dot-5">
                    <div className="dot"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="col-lg-7">
            <div className="home-cover-content-wrap">
              
              <h1 className="heading-large">
                <Trans>Listen & Download Holy Quran MP3</Trans>
              </h1>
              <h2 className="intro-description">
              <Trans>hero-text</Trans>
              </h2>
              <HomeHeroSubscription />
              <SocialLinks />
            </div>
          </div>
        </div>
      </div>
      <div style={{paddingTop:'2rem'}}>
      <Banner/>
      </div>
    </section>
  );
};

export default HomeHero;
